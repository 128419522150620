// .pageHeader .headerContent .mainLogo {
//   order: 5;
// }

// body {
//   background-color: $green-dark;
//   color: white;
// }

@media (min-width: $nav-breakpoint) {
  .pageHeader .headerContent {
    padding-bottom: 0;
  }
}

#menuAdmin{
  font-family: 'Quicksand', sans-serif;
}

ul.subMenu li.hasSubmenu > a:after {
  color: $grey-dark;
}

@media (min-width: $nav-breakpoint) {
    .carouselDetailWrapper {
      bottom: 30px;
    }
}

.carouselControls, .carouselButtons {
  max-width: $container-max-width;
  margin: 0 auto;
}

.carouselControls {
  bottom: 10px;
}

.carouselButtons {
  bottom: 25px;
  padding-right: 130px;
}

.carouselButtons .activeSlide a {
  color: $green-light!important;
}

.carouselSlideHeading a {
  text-decoration: none;
}

.homeIntroWrapper {
  color: initial;
  padding-bottom: 3rem;
}
.homeIntro a:not(.button) {
  color: $green-darker; 
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.pageFooterWrapper {
  font-family: 'Quicksand', sans-serif;
  background-image: url($assets-path + 'rose.svg');
  background-repeat: no-repeat;
  background-position: bottom 30px right 20px;
  background-size: 150px;
  a {
    font-weight: 700;
  }
}

.pageFooter {
    padding: 2rem 0 4rem;
}

.footerBox5 {
  display: flex;
  padding-right: 200px;
  align-items: center;
  flex-wrap: wrap;
  img {
    width: auto;
    height: auto;
    max-width: 200px;
    max-height: 90px;
    margin: 20px 40px 20px 0;
    object-fit: contain;
    object-position: center;
    filter: grayscale(1);
  }
}

.customForm #formCaptcha2, #lblFormCaptcha2, #honeybeerit, #honeybeerit2, #honeybeeritn, #honeybeeritn2, #honeybeeritc, #honeybeeritc2, #honeybeeritu, #honeybeeritu2, #honeybeeritl, #honeybeeritl2, #honeybeeritf, #honeybeeritf2, #honeybeeritb, #honeybeeritb2, #honeybeeritr, #honeybeeritr2, #honeybeeritp, #honeybeeritp2 {
  display: none;
}

.quickGivingPanel .selectDonationAmount > span:not(:first-child), .quickGivingPanel .selectDonationAmount > a[href*="repeat-payment"], .quickGivingPanel .regularPayment {
  display: inline-block;
  margin-top: 20px;
}

.breadcrumbItem:before {
  //color: white;
}

// .postContent, .listContent {
//   a:not(cta-button):not(.button) {
//     text-decoration: underline;
//   }
// }
ul.socialIcons,
ul.shareList,
#menuAdmin,
#menuMain,
.departmentList {
  a {
    text-decoration: none!important;
  }
}

.shareList a:before {
    color: $black!important;
}

table {
  th, td {
    border-top: none;
  }
}

.eventsPostBody, .appealsPostBody {
  .breadcrumbWrapper,
  .post .contentBlockWrapper, .post .headerText .title {
    max-width: $container-max-width;
  }
}

.raisedWrapper .totaliserFigure,
.commentDonationAmount {
  color: $green-dark;
}